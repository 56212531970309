.a4_page {
  height: 29.7cm; /* A4 height */
  width: 21cm; /* A4 width */
  padding: 2cm; /* inner padding */
  margin: 1cm auto; /* center the page on screen */
  border: 1px #d3d3d3 solid; /* optional border */
  background: white; /* optional white bg */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* optional shadow */
}

.page {
  width: 794px;
  height: 1000px !important;
  /* width: 21cm; */
  /* height: 29.7cm; */
  padding: 1cm;
  margin: 0 auto;
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); */
  background: #fff;
  overflow: auto !important;
}

.page .horizontal-line {
  background: #efefef;
  margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
  .page {
    width: 100%;
    height: auto;
    /* width: 21cm; */
    /* height: 29.7cm; */
    padding: 1cm;
    margin: 0 auto;
    /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); */
    background: #fff;
  }
}

@media print {
  .page {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none;
    background: white !important;
  }
  .fr-box.fr-document {
    padding: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
}

.rdw-suggestion-dropdown {
  position: relative !important;
  top: -20px !important;
  left: 15px !important;
}

.rdw-suggestion-option {
  display: block !important;
}

.rdw-editor-main {
  overflow: hidden !important;
}

.demo-wrapper {
  overflow: auto !important;
  max-height: 40vh !important;
}

@media (max-width: 767px) {
  .demo-wrapper {
    overflow: auto !important;
    max-height: 12vh !important;
  }

  #signature-block {
    width: 100% !important;
    img {
      max-width: 100% !important;
    }
  }
}

.page-break {
  page-break-before: always;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #efefef; */
  left: 0;
  margin: 15px;
  width: 100%;
  text-align: center;
}

.preview .page-break.pagebreakdelete.ck-widget.ck-widget_selected {
  margin: 0 -50px !important;
}

.page-break-label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  /* border: 1px solid #c4c4c4; */
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: 700;
  color: #333;
  /* background: #fff; */
  /* box-shadow: 2px 2px 1px rgb(0 0 0 / 15%); */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background-color: #fff;
  color: #000;
  padding: 0 0.5rem;
}

.page-break:hover {
  cursor: pointer;
}

.page-break:hover .page-break-label {
  background-color: tomato;
  box-shadow: 2px 2px 1px rgb(0 0 0 / 15%);
  content: "Delete Page Break";
  color: #fff;
}

.page-break:hover .page-break-label:after {
  content: "Delete Page Break";
}

.page-break:hover .page-break-label {
  background-color: tomato;
  box-shadow: 2px 2px 1px rgb(0 0 0 / 15%);
  content: "Delete Page Break";
  color: #fff;
}

.enabled-close .user-signature:hover .user-sign {
  display: block;
}

@media print {
  .page {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    box-shadow: none;
    background: white;
  }

  .page-break {
    all: unset;
    page-break-before: always;
    clear: both;
    padding: 0;
    margin: 0;
    height: 0px;
    width: 100%;
  }
  .page-break-label {
    display: none;
  }
}

.debug,
.debug-child > *,
.debug-all * {
  @apply outline outline-1;
}

.ReactModal__Body--open {
  overflow: hidden;
}

/* Main calendar container styles */
.react-datepicker {
  @apply w-full h-auto !border-0;

  &:has(:not(.react-datepicker__day--disabled)) {
    @apply border border-AccentRegular;
  }

  .react-datepicker__month-container {
    @apply w-full h-full;
    box-shadow: none;
  }

  /* Individual dates */
  .react-datepicker__day {
    @apply inline-flex items-center justify-center w-10 h-10;

    &:hover {
      @apply text-white;
    }

    &:not(.react-datepicker__day--disabled) {
      @apply border rounded-full border-AccentRegular;
    }
  }

  /* Today's date */
  .react-datepicker__day--today {
    &:hover {
      @apply !bg-AccentMediumDark !text-white;
    }
  }

  /* Active selected date */
  .react-datepicker__day--selected {
    @apply !text-white !bg-AccentRegular;
  }

  /* Styles for the disabled dates */
  .react-datepicker__day--disabled {
    @apply cursor-not-allowed !text-Neutral500 font-semibold !border-neutral-200 !bg-white;
  }

  /* Month container */
  .react-datepicker__month {
    @apply space-y-1;
  }

  .react-datepicker__month-container {
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        @apply w-10;
      }
    }
  }
}

.tribute-container ul {
  background: #ffffff !important;
  border: 1px solid #6e6df2 !important;
  border-radius: 8px !important;
}

.tribute-container ul li {
  border-bottom: 1px solid #6e6df2 !important;
}

.tribute-container li:last-child {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border: 0 !important;
}
.tribute-container li.highlight {
  background: #6e6df24d !important;
}
.tribute-container {
  max-height: 320px !important;
}

/* Sub domain url */
#subdomainUrl:valid {
  border: 1px solid;
  border-color: green;
}
#subdomainUrl:invalid {
  border: 1px solid;
  border-color: red;
}
.subdomain-red {
  border: 1px solid;
  border-color: red !important;
}

.formatted-content {
  ul {
    @apply pl-5 my-2 space-y-1;

    li {
      @apply list-disc;
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
