.source-code-pro {
  font-family: "Source Code Pro";
}
.source-noto-serif {
  font-family: "Noto Serif";
}
.font-kalam {
  font-family: "Kalam", cursive;
}
.font-mr-dafoe {
  font-family: "Mr Dafoe", cursive;
}
.font-inter {
  font-family: "Inter", sans-serif;
}

.font-feature-settings {
  font-feature-settings: "tnum" on, "lnum" on;
}
.perpul-grdiant {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  );
}
/* buttons start */
.btn {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
  -ms-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
  -o-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #6d6df2;
}
.btn-hover:hover {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.25) 100%
    ),
    #6d6df2;
}
.large-primary-btn:focus {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #6d6df2;
}
.form-primary-btn:focus {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #6d6df2;
}
.invoice-changes:hover {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.25) 100%
    ),
    #6d6df2;
  background-color: #6d6df2 !important;
  opacity: 1;
}

.button-hover {
  background-color: #6d6df2;
  transition: all 0.3s ease-in;
}

.button-hover:hover {
  background-color: color-mix(in srgb, var(--accent-color) 70%, black);
}

.go-to-builder-button {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
}
/* .button-hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  border-radius: 4px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.25) 100%
    ),
    #6d6df2;
}
.button-hover:hover::after {
  opacity: 1;
} */
/* buttons end */

.clientslist li.client {
  transition: 0.3s;
}
.clientslist:hover li {
  margin-right: 0px;
}
body .passwordshow .strength-bar div:first-child div {
  min-height: 4px;
  border-radius: 4px;
}
body .passwordshow .strength-word {
  display: flex;
  margin-top: 8px;
  font-weight: 500;
}
.menulist .menuitems:nth-child(2),
.menulist + .menuitems,
.menulist + .menuitems + .menuitems {
  margin-top: 20px;
}
.menulist .menuitems::before {
  @apply text-Neutral600 text-[10px] 5xl:text-sm font-semibold mb-2 inline-block tracking-[0.04em] ml-3;
}
.menulist .menuitems:nth-child(1)::before {
  content: "OVERVIEW";
}
.menulist .menuitems:nth-child(2)::before {
  content: "PROJECT MANAGEMENT";
}
.menulist .menuitems:nth-last-of-type(2) {
  /* margin-top: 48px; */
}
.menulist .menuitems:nth-last-of-type(2)::before {
  /* content: "MISCELLANEOUS"; */
}

.menulist .menuitems::before {
  display: none;
}

.menu-title-delimiter {
  @apply uppercase text-Neutral500 text-[10px] 5xl:text-sm font-semibold mb-2 inline-block tracking-[0.04em] ml-3;
}

.menulist .menuitems:not(:first-child),
.menulist .menuitems:not(:last-child) {
  margin-bottom: 3px;
}

/* ===== Scrollbar CSS Start ===== */
* {
  scrollbar-width: auto;
  scrollbar-color: #c2c2cc #ffffff;
}
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 8px;
  margin: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #c2c2cc;
  border-radius: 8px;
}
.sidebar-main::-webkit-scrollbar {
  width: 4px;
}
p::-webkit-scrollbar {
  width: 4px;
}
.scrollbar-w-4::-webkit-scrollbar {
  width: 4px;
}
.scrollbar-w-0::-webkit-scrollbar {
  width: 0px;
}
.scrollbar-h-4::-webkit-scrollbar {
  height: 4px;
}
textarea::-webkit-scrollbar {
  width: 4px;
}
.checkbox-main::-webkit-scrollbar {
  width: 4px;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
/* ===== Scrollbar CSS End ===== */

.main-content,
.admin-navbar {
  width: calc(100% - 240px);
}

@media only screen and (min-width: 2048px) {
  .admin-navbar,
  .main-content {
    width: calc(100% - 300px);
  }
  .menulist .menuitems::before {
    font-size: 87.5%;
    line-height: 1.4;
  }
  body .text-xs {
    font-size: 100%;
    line-height: 1.4;
  }
  body .text-sm {
    font-size: 112.5%;
    line-height: 1.4;
  }
  body .text-base {
    font-size: 125%;
    line-height: 1.4;
  }
  body .text-lg {
    font-size: 137.5%;
    line-height: 1.4;
  }
  body .text-xl {
    font-size: 150%;
  }
  body .add-time-date-tracking input {
    font-size: 18px;
  }
  body #projects-edit-time {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1880px) {
  .trackingpills-main .pills-status-scroll-right::after {
    content: "";
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    height: calc(100% - 4px);
    width: 100%;
    z-index: 99;
    background: linear-gradient(
      270deg,
      rgb(252, 252, 252) 0%,
      rgba(252, 252, 252, 0) 35.34%
    );
  }
  .trackingpills-main .pills-status-scroll-right::before {
    background: linear-gradient(
      90deg,
      rgb(252, 252, 252) 0%,
      rgba(252, 252, 252, 0) 35.34%
    );
  }
}
@media only screen and (max-width: 1023px) {
  /* .sidebar-main {
    padding-left: 20px;
    padding-right: 20px;
    background-color: #ffffff;
  }
  .sidebar-main .menuitems a {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  } */
  .sidebar-main .menuitems a {
    padding-left: 12px;
    padding-right: 12px;
    justify-content: start;
  }
  /* .medium-screen-hover {
    opacity: 0;
    width: 0;
    transition: 0.3s;
  } */
  /* .menulist .medium-screen-hover {
    margin-left: 0;
  } */
  /* .medium-screen .sidebar-main .medium-screen-hover {
    opacity: 1;
    width: fit-content;
  } */
  /* .medium-screen .sidebar-main .menulist .medium-screen-hover {
    margin-left: 12px;
  } */
  /* .menulist .menuitems::before {
    transition: 0.3s;
  } */
  /* .menulist .menuitems:nth-child(2) {
    margin-top: 8px;
  } */
  /* .menulist .menuitems:nth-child(1)::before {
    content: "";
  } */
  .sidebar-main:hover .menulist .menuitems:nth-child(1)::before {
    content: "OVERVIEW";
    border: 0;
    width: 100%;
  }
  /* .menulist .menuitems:nth-child(2)::before {
    content: "";
    border: 1px solid #ededf2;
    height: 1px;
    width: 32px;
    margin-left: 0;
  } */
  .sidebar-main:hover .menulist .menuitems:nth-child(2)::before {
    content: "PROJECT MANAGEMENT";
    border: 0;
    width: 100%;
    margin-bottom: 0.5rem;
    margin-left: 0.75rem;
  }

  .site-logo {
    width: 32px;
  }
  .sidebar-main div,
  .sidebar-main a,
  .sidebar-main p,
  .sidebar-main img,
  .sidebar-main ::after,
  .sidebar-main ::before {
    transition: 300ms;
  }
  .sidebar-main {
    width: 72px;
    transition: 0.3s;
    overflow: hidden;
  }
  .sidebar-main:hover {
    width: 240px;
  }
  .main-content {
    /* margin-left: 72px; */
    transition: 0.3s;
  }
  .admin-navbar,
  .main-content {
    width: calc(100% - 72px);
  }
  .sidebar-main:hover .main-content {
    margin-left: 0;
    left: 240px;
    width: 100%;
  }
  .medium-screen {
    overflow: hidden;
  }
  .selected::after {
    content: "";
    position: absolute;
    left: -20px;
    height: 20px;
    width: 4px;
    background: #6d6df2;
    border-radius: 0px 2px 2px 0px;
  }
  .sidebar-main:hover .selected::after {
    display: none;
  }
}

/* modal start */
.add-event-time-date input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
.add-event-time-date input[type="date"] {
  display: block;
  position: relative;
  background: white url(../Images/calendar.svg) right 12px center no-repeat;
  cursor: pointer;
}
.add-time-date-tracking input {
  display: block;
  position: relative;
  background: white url(../Images/calendar.svg) right 12px center no-repeat;
  cursor: pointer;
}
.add-event-time-date input[type="date"]:invalid::-webkit-datetime-edit {
  color: #c2c2cc;
}
.add-event-time-date input[type="date"] {
  color: #5a5a66;
}
.add-event-time-date input::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.ReactModal__Overlay {
  backdrop-filter: blur(8px);
  z-index: 9999;
  overflow: auto;
}
/* modal end */

.dropdowns-boxshadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.19),
    inset 0px -32px 1px rgba(255, 255, 255, 0.9);
}
body .client-edite-view-shadow {
  border: 0.5px solid #f5f5f7;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
body .team-member-shadow {
  box-shadow: 0px 3px 6px rgb(0 0 0 / 10%);
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

/* dropdown checkbox  */
.checkbox-main .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.checkbox-main .form-group label {
  position: relative;
  cursor: pointer;
}
.checkbox-main .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #ededf2;
  border-radius: 4px;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 12px;
}
.checkbox-main .form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.checkbox-main .form-group input:checked + label:before {
  background: var(--accent-color); /* #6d6df2 */
  border-color: var(--accent-color); /* #6d6df2 */
}
.formgroupmain:hover label:before {
  border-color: var(--accent-color); /* #6d6df2 */
}
.formgroupmain:hover label {
  color: #292933;
}
.checkbox-main::after {
  content: "";
  position: fixed;
  border-radius: 0 0 8px 8px;
  bottom: 0;
  height: 40px;
  width: 100%;
  background: linear-gradient(
    182.49deg,
    rgba(255, 255, 255, 0) 23.64%,
    #ffffff 92.32%
  );
}
.checkbox-main .formgroupmain:nth-child(1) .edit-delete-menu {
  top: 40px;
}
.checkbox-main .formgroupmain:nth-child(2) .edit-delete-menu {
  top: 40px;
}
.checkmarkicon {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--accent-color);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
}

.navbar svg path {
  transition: 0.3s;
}
.navbar:hover svg path {
  stroke: var(--accent-color); /* #6d6df2 */
}
.navbar.active svg path {
  stroke: var(--accent-color); /* #262680 */
}
.navbar.active {
  background: var(--bg-color); /* f0f0ff */
}
.navbar.active h3 {
  color: var(--accent-color) /* #262680 */;
  font-weight: 600;
}
.navbar.active:hover h3 {
  color: var(--accent-color); /* #262680; */
  font-weight: 600;
}
.navbar:hover h3 {
  color: var(--accent-color); /* #6d6df2 */
}
.navbar:hover {
  background-color: var(--bg-color);
}
.popup-btn[aria-expanded="true"] svg path {
  stroke: var(--accent-color); /* #6d6df2 */
}
.popup-btn svg path {
  stroke: #5a5a66;
  transition: 0.3s;
}
.popup-btn:hover svg path {
  stroke: var(--accent-color); /* #6d6df2 */
}

.drags-btn:hover {
  cursor: grabbing;
}

.drag-btn[aria-expanded="true"] svg path {
  stroke: #757580;
}
.drag-btn svg path {
  stroke: #757580;
  transition: 0.3s;
}
.drags-btn:hover svg path {
  stroke: #757580;
}
.questionnaire-wrapper {
  background-color: #fff;
  box-shadow: 3px 1px 7px #e3e3e4;
}
.form-overlay::before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 28px;
  width: calc(100% - 4px);
  background: linear-gradient(
    179.01deg,
    rgba(255, 255, 255, 0) -97.12%,
    #ffffff 87.46%
  );
}
.builderinoshadow {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}
.documents-box-icon {
  background: #73a2ff;
}
.media-box-icon {
  @apply bg-Red400;
}
.assets-box-icon {
  background: linear-gradient(134.99deg, #e5675c 0%, #7b61ff 100.02%);
}
.invoices-box::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 35px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    #ffffff 100%,
    #ffffff 100%,
    #ffffff 100%
  );
  border-radius: 4px;
  z-index: 9999;
}
.status-scroll-right::after {
  content: "";
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
  height: calc(100% - 4px);
  width: 40%;
  z-index: 99;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 23%);
}
.status-scroll-left::before {
  content: "";
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  height: calc(100% - 4px);
  width: 40%;
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 23%);
}
.box-1 .bg-shape-1,
.box-2 .bg-shape-2,
.box-3 .bg-shape-3 {
  opacity: 20%;
}
body .color-1 svg path {
  fill: #e56794;
}
body .color-2 svg path {
  fill: #73a2ff;
}
body .color-3 svg path {
  fill: #58dbb0;
}
body .color-4 svg path {
  fill: #6d6df2;
}
body .color-5 svg path {
  fill: #5a5a66;
}
body .text-color-1 {
  color: #e56794;
}
body .text-color-2 {
  color: #73a2ff;
}
body .text-color-3 {
  color: #58dbb0;
}
body .text-color-4 {
  color: var(--accent-color); /* #6d6df2 */
}
body .text-color-5 {
  color: #5a5a66;
}
.color-1 svg path {
  fill: #e56794;
}
.color-2 svg path {
  fill: #73a2ff;
}
.color-3 svg path {
  fill: #58dbb0;
}
.color-4 svg path {
  fill: #6d6df2;
}
.color-5 svg path {
  fill: #5a5a66;
}
.text-color-1 {
  color: #e56794;
}
.text-color-2 {
  color: #73a2ff;
}
.text-color-3 {
  color: #58dbb0;
}
.text-color-4 {
  color: #6d6df2;
}
.text-color-5 {
  color: #5a5a66;
}
.bg-shap-main::after,
.request-info-bg-shap-main::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 100%;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.request-info-bg-shap-main::after {
  width: 80px;
}

.top-back-link:hover img {
  transform: scale(1.15);
}
.tasklists-main .tasklists-hover {
  border-color: #5a5a66;
}
body .tasklists-main .tasklists-focus {
  border-color: #6d6df2;
}
.pagination-buttons a:hover svg path {
  transition: 0.3s;
}
.pagination-buttons a:hover svg rect {
  transition: 0.3s;
}

.pagination-buttons a.pagination-left-btn svg path,
.pagination-buttons a.pagination-right-btn svg path {
  stroke: #757580;
}
.pagination-buttons a.pagination-left-btn svg rect,
.pagination-buttons a.pagination-right-btn svg rect {
  stroke: #757580;
}

.pagination-buttons a.pagination-left-btn:hover svg path,
.pagination-buttons a.pagination-right-btn:hover svg path {
  stroke: #292933;
}
.pagination-buttons a.pagination-left-btn:hover svg rect,
.pagination-buttons a.pagination-right-btn:hover svg rect {
  stroke: #292933;
}
textarea {
  resize: none;
  min-width: 50%;
}
.downarrow-icon {
  transform: rotate(180deg);
}
.downarrow-icon svg path {
  stroke: #6d6df2;
}
input.timeinput[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}
.notification-teg::before {
  content: "";
  position: absolute;
  top: -3.5px;
  right: 0.5px;
  width: 13px;
  height: 13px;
  background-color: var(--accent-color); /*  #6d6df2 */
  border: 1.5px solid #ffffff;
  border-radius: 25px;
  z-index: 999;
}
.pagination-tracking .pagination-main p {
  font-weight: 600;
}

/* ---------------- modal css ---------------- */
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(50px);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}

.ReactModal__Overlay--before-close .animate__fadeInUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
.animate__fadeInUp {
  animation-duration: 300ms;
}
/* ---------------- modal end ---------------- */

/* ---------------- Proposal Builder start ---------------- */
.builder-main {
  box-shadow: 0px 94px 80px rgba(0, 0, 0, 0.05),
    0px 55.3472px 41.728px rgba(0, 0, 0, 0.03616),
    0px 28.4256px 19.584px rgba(0, 0, 0, 0.02808),
    0px 11.4304px 8.576px rgba(0, 0, 0, 0.02192),
    0px 2.5568px 3.712px rgba(0, 0, 0, 0.01384);
}
.builder-img-box {
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
}
.hovericon-color:hover svg path {
  stroke: #fcfcfc;
}
body .seenotes {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #6d6df2;
  box-shadow: 0px 1.5px 3px rgba(0, 0, 0, 0.2),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
  border-radius: 4px;
}
.costom-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.costom-checkbox label {
  position: relative;
  cursor: pointer;
}
.costom-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #ededf2;
  border-radius: 4px;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 12px;
}
.costom-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.costom-checkbox input:checked + label:before {
  background: #6d6df2;
  border-color: #6d6df2;
}
.rounde-btn::after {
  border-radius: 100%;
}
.add-image-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 84.62%
  );
  border-radius: 12px;
  z-index: 10;
}
/* ---------------- Proposal Builder end ---------------- */

/* ---------------- Datepicker start ---------------- */
.costom-react-datepicker .react-datepicker {
  border: 0.5px solid #dfdfe6;
}
.costom-react-datepicker .react-datepicker__header {
  background-color: #ededf2;
  border-bottom: 0.5px solid #dfdfe6;
}
.costom-react-datepicker .react-datepicker__current-month,
.costom-react-datepicker .react-datepicker__day-name,
.costom-react-datepicker .react-datepicker__day {
  color: #5a5a66;
}
.costom-react-datepicker
  .react-datepicker__day.react-datepicker__day--selected {
  color: #fff;
  background-color: #6d6df2;
}
.costom-react-datepicker .react-datepicker__day--keyboard-selected,
.costom-react-datepicker .react-datepicker__month-text--keyboard-selected,
.costom-react-datepicker .react-datepicker__quarter-text--keyboard-selected,
.costom-react-datepicker .react-datepicker__year-text--keyboard-selected {
  color: #fff;
  background-color: #6d6df2;
}
.costom-react-datepicker
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  border-top-color: #dfdfe6;
}
.costom-react-datepicker
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #dfdfe6;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.costom-react-datepicker .react-datepicker__day:hover,
.costom-react-datepicker .react-datepicker__month-text:hover,
.costom-react-datepicker .react-datepicker__quarter-text:hover,
.costom-react-datepicker .react-datepicker__year-text:hover {
  background-color: #f5f5f7;
}

.costom-react-datepicker .react-datepicker__day,
.costom-react-datepicker .react-datepicker__month-text,
.costom-react-datepicker .react-datepicker__quarter-text,
.costom-react-datepicker .react-datepicker__year-text {
  transition: 0.3s;
}

.costom-react-datepicker .react-datepicker__year-read-view--down-arrow,
.costom-react-datepicker .react-datepicker__month-read-view--down-arrow,
.costom-react-datepicker .react-datepicker__month-year-read-view--down-arrow,
.costom-react-datepicker .react-datepicker__navigation-icon::before {
  border-color: #c2c2cc;
  transition: 0.3s;
}
.costom-react-datepicker .react-datepicker__navigation:hover *::before {
  border-color: #757580;
}
.costom-react-datepicker
  .react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
  background-color: #6d6df2;
}
.costom-react-datepicker
  .react-datepicker__day.react-datepicker__day--selected:hover {
  background-color: #6d6df2;
}
/* ---------------- Datepicker end ---------------- */

/* ---------------- text editer ---------------- */

.editorClassName {
  width: 790px;
  height: 1116px !important;
  margin: auto;
  min-height: 700px;
  background-color: #fff;
  padding: 76px 48px 66px 45px;
  box-shadow: 0px 94px 80px rgba(0, 0, 0, 0.05),
    0px 55.3472px 41.728px rgba(0, 0, 0, 0.03616),
    0px 28.4256px 19.584px rgba(0, 0, 0, 0.02808),
    0px 11.4304px 8.576px rgba(0, 0, 0, 0.02192),
    0px 2.5568px 3.712px rgba(0, 0, 0, 0.01384);
}
.editor-shadow {
  box-shadow: 0px 94px 80px rgba(0, 0, 0, 0.05),
    0px 55.3472px 41.728px rgba(0, 0, 0, 0.03616),
    0px 28.4256px 19.584px rgba(0, 0, 0, 0.02808),
    0px 11.4304px 8.576px rgba(0, 0, 0, 0.02192),
    0px 2.5568px 3.712px rgba(0, 0, 0, 0.01384);
}
.toolbarClassName {
  position: fixed;
  bottom: 48px;
  top: auto;
  left: calc(50% + 120px);
  width: 1031px !important;
  transform: translateX(-50%);
  border: 1px solid #dfdfe5;
  height: 72px;
  background-color: #fff;
  padding: 0px 24px;
  border-radius: 4px;
  z-index: 999;
  align-items: center;
}

/* .rdw-option-wrapper:hover {
  box-shadow: none !important;
  border: 1px solid !important;
  height: auto !important;
  width: auto !important;
} */

.rdw-dropdown-wrapper:hover {
  box-shadow: none !important;
  background-color: #ffffff;
}

.rdw-dropdown-wrapper {
  border: none !important;
}

.rdw-option-wrapper {
  border: none !important;
}

/* .rdw-option-active {
  box-shadow: none !important;
  border: 1px solid black !important;
  height: auto !important;
  width: auto !important;
} */

body .editorClassName h1 {
  font-size: 32px;
  font-weight: 700;
}
body .editorClassName h2 {
  font-size: 24px;
  font-weight: 700;
}
body .editorClassName h3 {
  font-size: 18px;
  font-weight: 700;
}
body .editorClassName h4 {
  font-size: 16px;
  font-weight: 700;
}
body .editorClassName h5 {
  font-size: 13px;
  font-weight: 700;
}
body .editorClassName h5 {
  font-size: 10px;
  font-weight: 700;
}

body .editorClassName hr {
  width: 120px;
  margin: 24px auto;
  border-color: #dfdfe6;
}

/* .rdw-image-wrapper {
  box-shadow: none !important;
  border: 1px solid black !important;
  height: auto !important;
  width: auto !important;
} */

.placeholder-ul {
  visibility: hidden;
}
.placeholder-li:hover {
  background: #f1f1f1;
}

/* JODIT Editor CSS which is in not use now */

body .jodit-react-container .jodit-toolbar__box {
  position: fixed;
  bottom: 48px;
  top: auto;
  left: calc(50% + 120px);
  width: 1031px !important;
  transform: translateX(-50%);
  border: 1px solid #dfdfe5;
  height: 72px;
  background-color: #fff;
  padding: 0px 24px;
  border-radius: 4px;
  z-index: 999;
}
body
  .jodit-react-container
  .jodit-toolbar__box
  .jodit-toolbar-editor-collection {
  background-image: none;
}
body
  .jodit-react-container
  .jodit-toolbar__box
  .jodit-ui-group.jodit-ui-group_size_middle {
  width: 100%;
  justify-content: space-between;
  height: 72px;
  align-items: center;
}
.jodit-container.jodit.jodit_theme_default.jodit-wysiwyg_mode {
  margin: auto;
  border: none;
}
.jodit-container.jodit.jodit_theme_default {
  box-shadow: 0px 94px 80px rgba(0, 0, 0, 0.05),
    0px 55.3472px 41.728px rgba(0, 0, 0, 0.03616),
    0px 28.4256px 19.584px rgba(0, 0, 0, 0.02808),
    0px 11.4304px 8.576px rgba(0, 0, 0, 0.02192),
    0px 2.5568px 3.712px rgba(0, 0, 0, 0.01384);
}
.box-shadow-hidden .signature .bg-white {
  box-shadow: 0px 94px 80px rgba(0, 0, 0, 0.05),
    0px 55.3472px 41.728px rgba(0, 0, 0, 0.03616),
    0px 28.4256px 19.584px rgba(0, 0, 0, 0.02808),
    0px 11.4304px 8.576px rgba(0, 0, 0, 0.02192),
    0px 2.5568px 3.712px rgba(0, 0, 0, 0.01384);
}
body .jodit-react-container .jodit-status-bar,
body .jodit-react-container .jodit-add-new-line,
body .jodit-react-container .jodit-container .jodit-editor__resize,
.signature-icon {
  display: none;
}
body .jodit-react-container .jodit-workplace {
  background-color: #fff;
  padding: 36px 48px 26px 45px;
}
body .jodit-react-container .jodit-workplace span.jodit-placeholder {
  padding: 42px 48px 26px 55px;
}
body .jodit-react-container .jodit-toolbar__box,
body .upload__image-wrapper .relative {
  visibility: hidden;
}
body .preview-disable .jodit-react-container .jodit-toolbar__box,
body .preview-disable .upload__image-wrapper .relative {
  visibility: visible;
}
body .preview .image-item__btn-wrapper,
body .preview .upload__image-wrapper .relative {
  display: none;
}
body .preview .signature {
  visibility: visible;
}
body .jodit-react-container .jodit-workplace hr {
  width: 120px;
  margin: 24px auto;
  border-color: #dfdfe6;
}
body .preview .relativeCSS {
  pointer-events: none;
}
body .jodit-react-container .jodit-workplace .jodit-wysiwyg h1 {
  font-size: 32px;
  font-weight: 700;
}
body .jodit-react-container .jodit-workplace .jodit-wysiwyg h2 {
  font-size: 24px;
  font-weight: 700;
}
body .jodit-react-container .jodit-workplace .jodit-wysiwyg h3 {
  font-size: 18px;
  font-weight: 700;
}
body .jodit-react-container .jodit-workplace .jodit-wysiwyg h4 {
  font-size: 16px;
  font-weight: 700;
}
body .jodit-react-container .jodit-workplace .jodit-wysiwyg h5 {
  font-size: 13px;
  font-weight: 700;
}
body .jodit-react-container .jodit-workplace .jodit-wysiwyg h5 {
  font-size: 10px;
  font-weight: 700;
}
body
  .jodit-react-container
  .jodit-toolbar__box
  .jodit-ui-group.jodit-ui-group_size_middle
  span.jodit-toolbar-button__icon
  svg {
  fill: #5a5a66;
}
body .jodit-ui-button_variant_primary,
body .jodit-ui-button_variant_primary:hover {
  background: #6c6cef;
}
/* ---------------- text editer ---------------- */
/* ---------------- modal end ---------------- */

/* ---------------- Loader animation ---------------- */
.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem 0;
  margin: 0 -5%;
  overflow: hidden;
}
.dot-falling {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--accent-color); /* #6d6df2 */
  color: var(--accent-color); /* #6d6df2 */
  box-shadow: 9999px 0 0 0 var(--accent-color); /* #6d6df2 */
  animation: dotFalling 1s infinite linear;
  animation-delay: 0.1s;
}

.dot-falling::before,
.dot-falling::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--accent-color); /* #6d6df2 */
  color: var(--accent-color); /* #6d6df2 */
  animation: dotFallingBefore 1s infinite linear;
  animation-delay: 0s;
}

.dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--accent-color); /* #6d6df2 */
  color: var(--accent-color); /* #6d6df2 */
  animation: dotFallingAfter 1s infinite linear;
  animation-delay: 0.2s;
}

@keyframes dotFalling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 var(--accent-color); /* #6d6df2 */
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingBefore {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 var(--accent-color); /* #6d6df2 */
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingAfter {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 var(--accent-color); /* #6d6df2 */
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

/* white */

.white.dot-falling {
  background-color: var(--bg-color); /* #4747b2 */
  color: var(--bg-color); /* #4747b2 */
  box-shadow: 9999px 0 0 0 var(--bg-color); /* #4747b2 */
  animation: whiteDotFalling 1s infinite linear;
  animation-delay: 0.1s;
}

.white.dot-falling::before {
  background-color: var(--bg-color); /* #4747b2 */
  color: var(--bg-color); /* #4747b2 */
  animation: whiteDotFallingBefore 1s infinite linear;
  animation-delay: 0s;
}

.white.dot-falling::after {
  background-color: var(--bg-color); /* #4747b2 */
  color: var(--bg-color); /* #4747b2 */
  animation: whiteDotFallingAfter 1s infinite linear;
  animation-delay: 0.2s;
}

.client-hover:hover::before {
  opacity: 0.5;
}
.client-hover:before {
  content: "";
  height: calc(100% - 6px);
  width: calc(100% - 6px);
}

@keyframes whiteDotFalling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 var(--bg-color); /* #4747b2 */
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes whiteDotFallingBefore {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 var(--bg-color); /* #4747b2 */
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes whiteDotFallingAfter {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 var(--bg-color); /* #4747b2 */
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

.selctedbox {
  width: 72px;
  height: 72px;
}
.selctedbox.box-1.color-1 {
  background-image: url(../Images/p-1-color-1.svg);
}
.selctedbox.box-1.color-2 {
  background-image: url(../Images/p-1-color-2.svg);
}
.selctedbox.box-1.color-3 {
  background-image: url(../Images/p-1-color-3.svg);
}
.selctedbox.box-1.color-4 {
  background-image: url(../Images/p-1-color-4.svg);
}
.selctedbox.box-1.color-5 {
  background-image: url(../Images/p-1-color-5.svg);
}
.selctedbox.box-2.color-1 {
  background-image: url(../Images/p-2-color-1.svg);
}
.selctedbox.box-2.color-2 {
  background-image: url(../Images/p-2-color-2.svg);
}
.selctedbox.box-2.color-3 {
  background-image: url(../Images/p-2-color-3.svg);
}
.selctedbox.box-2.color-4 {
  background-image: url(../Images/p-2-color-4.svg);
}
.selctedbox.box-2.color-5 {
  background-image: url(../Images/p-2-color-5.svg);
}
.selctedbox.box-3.color-1 {
  background-image: url(../Images/p-3-color-1.svg);
}
.selctedbox.box-3.color-2 {
  background-image: url(../Images/p-3-color-2.svg);
}
.selctedbox.box-3.color-3 {
  background-image: url(../Images/p-3-color-3.svg);
}
.selctedbox.box-3.color-4 {
  background-image: url(../Images/p-3-color-4.svg);
}
.selctedbox.box-3.color-5 {
  background-image: url(../Images/p-3-color-5.svg);
}
.loader-stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 25px;
  margin: 0 -5%;
  overflow: hidden;
}

.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
    18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #9880ff,
      0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff;
  }
  75% {
    box-shadow: 0 -18px 0 0 #9880ff,
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 0 #9880ff;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #9880ff;
  }
}
.preview-disable .overlay-set:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #292933;
  z-index: 9;
  opacity: 0.2;
}

body .jodit-react-container .jodit-workplace p a {
  color: #6d6df2;
  cursor: pointer;
  font-weight: 600;
}
.jodit-toolbar-editor-collection .jodit-toolbar-button {
  position: relative;
  overflow: visible;
}
.jodit-toolbar-editor-collection .jodit-toolbar-button::before {
  content: "";
  position: absolute;
  right: -29px;
  height: 100%;
  width: 1px;
  background: #c2c2cc;
}
.jodit-toolbar-editor-collection .jodit-toolbar-button:nth-child(4):before,
.jodit-toolbar-editor-collection .jodit-toolbar-button:nth-child(5):before,
.jodit-toolbar-editor-collection .jodit-toolbar-button:nth-child(6):before,
.jodit-popup__content .jodit-toolbar-button::before {
  display: none;
}
body .jodit-popup__content {
  overflow-x: hidden;
}
.bg-questionnaire {
  background: linear-gradient(180deg, #6d6df2 0%, #4646b9 100%);
  box-shadow: inset 0px 0.25px 0px rgba(255, 255, 255, 0.2),
    inset 0px -0.25px 0px rgba(0, 0, 0, 0.2);
}
.questionnaire-text::before {
  background: linear-gradient(
    179.01deg,
    rgba(255, 255, 255, 0) -97.12%,
    #ffffff 87.46%
  );
}
@media (min-width: 1200px) and (max-width: 1335px) {
  body .jodit-react-container .jodit-toolbar__box {
    width: 900px !important;
  }
}
.more-btn:before {
  content: "";
  position: absolute;
  pointer-events: none;
  left: 0px;
  bottom: -8px;
  height: 100%;
  width: calc(100% - 0px);
  background: linear-gradient(0deg, #f5f5f7 0%, rgba(255, 255, 255, 0) 47.34%);
}
.scrollbar-with::-webkit-scrollbar {
  width: 3px;
}
body .tooltip-font-size {
  font-size: 12px;
  padding: 0px 8px;
  font-weight: 400px;
}

/* animation css */
.skeleton-animation {
  background-size: 800px 104px;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
.button-Status {
  padding: 10px 18px;
}
.button-Status[aria-expanded="true"] {
  padding: 15px 18px;
  box-shadow: 0px 10px 40px rgb(0 0 0 / 20%);
}
.button-Status[aria-expanded="true"] + .origin-top-right {
  box-shadow: 0px 32px 40px rgb(0 0 0 / 20%);
}

.origin-top-right {
  box-shadow: 0px 10px 40px rgb(0 0 0 / 20%);
}
.button-Status[aria-expanded="true"] .menu-open-img,
.button-Status[aria-expanded="true"] .members-text,
.eam-text {
  display: none;
}
.border-top-set:before {
  position: absolute;
  content: "";
  width: 94%;
  background: #f5f5f7;
  height: 1px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}
.assigned-btn[aria-expanded="false"]:hover {
  background: #f5f5f7;
}
.assigned-btn[aria-expanded="true"]:before {
  width: 95%;
}
.assigned-btn[aria-expanded="true"] .eam-text {
  display: block !important;
}
.test[placeholder]:empty:before {
  content: attr(placeholder);
  color: #757580;
}
input.task-celender::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.download:hover svg path {
  stroke: #292933;
}
.delet-icon:hover svg path {
  stroke: #e5675c;
}
.currancy-scrollbar::-webkit-scrollbar {
  height: 32px !important;
}
.project-model.ReactModal__Content.ReactModal__Content--after-open {
  overflow: visible;
  width: 416px;
  margin-right: 0;
  margin: 0 auto !important;
  transform: translate(-20%, 30%) !important;
}
.Timetrackingdropdown {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
}
.pete:hover ~ .pete-popup {
  visibility: visible;
  opacity: 1;
}
.my-focus[aria-expanded="true"] {
  background-color: #f5f5f7;
}
.innermenu[aria-expanded="true"] ~ .innermenu-main {
  display: none !important;
}
.project-title-divide {
  border: 0 !important;
}
.client-shado {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
}
.client-btn-hover:hover svg path {
  stroke: #292933;
}
.phone-editer input.PhoneInputInput:focus {
  outline: none;
}
.phone-editer input.PhoneInputInput:focus {
  outline: none;
}
.phone-editer input.PhoneInputInput {
  padding-left: 26px;
  color: #5a5a66;
}
.phone-editer .PhoneInputCountry {
  width: 92px;
  padding: 9px 8px;
  justify-content: space-between;
  border-radius: 4px;
}

.phone-editer .PhoneInputCountrySelect {
  width: 100%;
  box-shadow: 10px 5px 5px white;
}
.phone-editer .PhoneInputCountry:before {
  content: "";
  width: 1px;
  height: 20px;
  display: block;
  position: absolute;
  right: -14px;
  background: #ededf2;
}
.phone-editer .PhoneInputCountry:hover {
  background: #ededf2;
}
.phone-editer .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 10px 5px 5px white;
}
.phone-editer
  .PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  color: #ddd;
}
.nots-before::before {
  background: linear-gradient(
    179.01deg,
    rgba(255, 255, 255, 0) 0.85%,
    #ffffff 87.46%
  );
  pointer-events: none;
}
.delete-hover:hover path,
.delete-hover:hover {
  stroke: #fff;
  color: #fff;
}
.client-name[aria-expanded="true"] {
  background: #fff !important;
  box-shadow: 0px 0px 40px rgb(0 0 0 / 20%);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.hourly-rate::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 1.5px;
  background: #ededf2;
  display: block;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
}
.home-page {
  background: #f5f5f7;
}
body .accountcontainer {
  max-width: 1440px;
  margin: auto;
  padding: 0 15px;
}
@media (max-width: 1440px) {
  body .accountcontainer {
    max-width: 100vw;
    margin: auto;
    padding: 0 15px;
  }
}
.indiviualsaccount {
  transition: all 0.5s;
}
.indiviualsaccount:hover {
  transform: translateY(-10px);
}
.public-proposal-shadow {
  box-shadow: 0px 94px 80px rgba(0, 0, 0, 0.05),
    0px 55.3472px 41.728px rgba(0, 0, 0, 0.03616),
    0px 28.4256px 19.584px rgba(0, 0, 0, 0.02808),
    0px 11.4304px 8.576px rgba(0, 0, 0, 0.02192),
    0px 2.5568px 3.712px rgba(0, 0, 0, 0.01384);
}
.card-shadow:hover {
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
}
.team-member,
.team-member-yello,
.orange-color,
.orange-green,
.orange-blue,
#Individuals-card {
  fill: #fff;
}
body .indiviualsaccount:hover .team-member-hover,
body .hover-state .team-member-hover {
  fill: #73a2ff;
}
body .indiviualsaccount:hover .team-member-yello,
body .hover-state .team-member-yello {
  fill: #ffff80;
}
body .indiviualsaccount:hover .orange-color,
body .hover-state .orange-color {
  fill: #e56780;
}
body .indiviualsaccount:hover .orange-green,
body .hover-state .orange-green {
  fill: #58dbb0;
}
body .indiviualsaccount:hover .orange-blue,
body .hover-state .orange-blue,
body .hover-state path#Individuals-card,
body .Individuals:hover path#Individuals-card {
  fill: #73a2ff;
}
.card-items-shadow {
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.05);
}
.Individuals:hover .icon-hover svg path,
.Individuals:hover .icon-hover svg rect,
.team-member,
.light,
.small-teams svg.w-full g[clip-path="url(#clip0_2854_46642)"],
.small-teams svg.w-full g[clip-path="url(#clip2_4813_72114)"],
.large-teams g[clip-path="url(#clip1_2927_51095)"],
.large-teams .orange,
.large-teams g[clip-path="url(#clip4_2927_51095)"],
.large-teams .large-teams-list svg path,
.large-teams .large-teams-list svg rect,
.small-teams svg.w-full g[clip-path="url(#clip4_4813_72114)"] {
  transition: all 0.3s;
}
.Individuals:hover .icon-hover svg path {
  stroke: #1d4f73;
}
.Individuals:hover .icon-hover svg rect {
  stroke: #5cace5;
}
.light {
  fill: #292933;
}
.small-teams:hover .light {
  fill: #6d6df2;
}
.small-teams svg.w-full g[clip-path="url(#clip4_4813_72114)"] {
  transform: translate(-56px, 0px);
}
.large-teams .duck {
  transform: translate(-40px, 0px);
  transition: all 0.3s;
}
.small-teams:hover svg.w-full g[clip-path="url(#clip4_4813_72114)"],
.large-teams:hover .duck {
  transform: translate(0px, 0px);
}
.small-teams svg.w-full g[clip-path="url(#clip3_4813_72114)"],
.small-teams svg.w-full g[clip-path="url(#clip1_4813_72114)"] {
  transform: translateX(22px);
}
.small-teams:hover .team-member-check svg path {
  stroke: #262680;
}
.small-teams:hover .team-member-check svg rect {
  stroke: #6d6df2;
}
.large-teams:hover .large-teams-list svg path {
  stroke: #73241d;
}
.large-teams:hover .large-teams-list svg rect {
  stroke: #e5675c;
}
.large-teams:hover .large-teams-card {
  background: #ffe8e6;
  color: #73241d;
}
.small-teams:hover .small-teams-card {
  background: #f0f0ff;
  color: #262680;
}
.Individuals:hover .Individuals-card {
  background: #d9efff;
  color: #1d4f73;
}

.filter-checkbox input:checked + .custom-chackbox {
  background-color: var(--accent-color); /*#6d6df2*/
  border: none;
}
.filter-checkbox input:checked + div svg {
  display: block;
  color: #fff;
}
.filter-checkbox input:checked + div {
  background-color: var(--accent-color); /* #d3d3f5 */
  border-color: #d3d3f5;
}
.filter-checkbox input:hover + div {
  background-color: var(--bg-color); /* #d3d3f5 */
  border-color: var(--accent-color); /* #6d6df2 */
}
.filter-checkbox input + div img {
  display: none;
}
.filter-checkbox input:checked + div img {
  display: block;
}
.toggle-switch input:checked + div {
  background-color: var(--bg-color); /* #d3d3f5 */
  border-color: var(--bg-color); /* #d3d3f5 */
}
.toggle-switch input:checked ~ .dot {
  transform: translateX(82%);
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--accent-color);
  box-shadow: 0px 1.5px 3px rgba(0, 0, 0, 0.2),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
}
@media only screen and (max-width: 1023px) {
  .select-account-type-swiper .swiper-button-prev::after {
    content: "";
    width: 24px;
    height: 24px;
    background-image: url(../Images/Back-nav-arrow-smill.svg);
    transform: rotate(180deg);
  }
  .select-account-type-swiper .swiper-button-next::after {
    content: "";
    width: 24px;
    height: 24px;
    background-image: url(../Images/Back-nav-arrow-smill.svg);
  }
  .select-account-type-swiper .swiper-button-next,
  .select-account-type-swiper .swiper-button-prev {
    top: unset;
    bottom: 0;
    height: auto;
  }
  .select-account-type-swiper {
    padding-bottom: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  body .onboading-form {
    min-height: calc(100vh - 50px);
    justify-content: space-between;
  }
  .onboading-form-content {
    display: flex;
    flex-flow: column;
    height: 50%;
    min-height: 50%;
    flex-grow: 0.5;
    justify-content: space-between;
  }
  .ReactModal__Overlay {
    backdrop-filter: blur(8px);
    z-index: 9999;
    overflow: hidden;
  }
  .fr-box.fr-basic .fr-element {
    height: 50vh;
  }
  .fr-toolbar .fr-command.fr-btn {
    margin: 0 !important;
  }
  .fr-toolbar .fr-command.fr-btn svg.fr-svg {
    height: 16px;
    width: 16px;
  }
  .froala-editor {
    max-width: 100% !important;
    width: 100% !important;
    margin-right: 0 !important;
    /* padding: 0 30px !important; */
    padding: 0 !important;
  }
  .fr-box.fr-basic .fr-element {
    overflow-x: hidden !important;
  }
}
.btn-no-data:hover span {
  color: #fff;
}
.assets-boxes:nth-child(2) .assets-boxes-empty {
  margin-top: -79px;
}
.assets-boxes:nth-child(3) .assets-boxes-empty {
  margin-top: -50px;
}
.time-tracking-shadow:hover {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}
body .selected-plane-set:hover {
  box-shadow: none !important;
  outline-color: #dfdfe5;
}
body .selected-plane-set {
  transform: translateY(-10px);
}
body .selected-plan {
  outline: 6px solid #6d6df2 !important;
}
body .selected-plan.yearly {
  outline: 6px solid #63e9c2 !important;
}

.plan-box:hover {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
}
.copy-btn {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
}
.back-btn-hover:hover svg path {
  stroke: #5a5a66;
}
.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  color: #7b7b85;
}
.fullcelender
  .css-njue5v
  .fc-day-today
  .fc-daygrid-day-frame
  .fc-daygrid-day-top
  .fc-daygrid-day-number {
  justify-content: center;
  display: flex;
  line-height: 14px;
  width: 21px;
  text-align: center;
  min-height: 21px !important;
  height: 21px;
  padding: 0;
  font-size: 12px !important;
  color: #f0f0ff !important;
  line-height: 21px;
  min-width: 21px !important;
  font-family: "Source Code Pro";
  font-weight: 400 !important;
}
.fullcelender .css-njue5v .fc-daygrid-dot-event .fc-event-title {
  color: #292933;
  font-weight: 400;
  font-size: 10px;
}
.fullcelender .fc-timegrid-event .fc-event-main {
  padding: 8px 12px;
  border: 0.5px solid #dfdfe6;
  border-radius: 8px;
}
.filtar-bar .filtar-bar-overlay {
  opacity: 0;
  pointer-events: none;
}
.filtar-bar .filtar-bar-content {
  transform: translateX(100%);
}
.filtar-bar {
  opacity: 0;
  pointer-events: none;
}
body .animated .filtar-bar-content {
  transform: translateX(100%) !important;
}
.custome-scrollbar::-webkit-scrollbar {
  width: 5px;
}
body .priority-card:nth-child(2) {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
body .priority-card:nth-child(1) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
body .priority-card:hover:after {
  opacity: 0;
  pointer-events: none;
}
body .priority-card {
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.07),
    inset 0px 1px 1px rgba(0, 0, 0, 0.05);
}
body .priority-active {
  box-shadow: none;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.15));
}
.not-active:hover {
  background: #dfdfe6;
}
.set-active::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  border-radius: 4px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.25) 100%
    ),
    #6d6df2;
}
.set-active:hover::after {
  opacity: 1;
}
.edit-menu-servies {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}
.project-title input#countries {
  color: #5a5a66;
  padding-left: 31px;
}
.project-title span.dot-box {
  display: block;
}
button.copy-link-btn {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #6d6df2;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 30%),
    inset 0px -0.5px 0px rgb(0 0 0 / 15%),
    inset 0px 0.5px 0px rgb(255 255 255 / 15%);
  border-radius: 4px;
}
/* 
.Proposal-Builder div:first-of-type {
  margin-top: 0!important;
} */

.Proposal-Builder .rdw-text-align-wrapper .rdw-option-wrapper,
.Proposal-Builder .rdw-inline-wrapper .rdw-option-wrapper,
.Proposal-Builder .rdw-colorpicker-wrapper .rdw-option-wrapper,
.Proposal-Builder .rdw-image-wrapper .rdw-option-wrapper {
  border: 1px solid transparent !important;
}
.Proposal-Builder
  .rdw-block-wrapper
  .rdw-dropdown-selectedtext:hover
  .rdw-dropdown-carettoopen {
  border-top: 6px solid #6d6df2;
}
.Proposal-Builder .rdw-block-wrapper .rdw-dropdown-selectedtext span {
  font-weight: 600;
  color: #5a5a66;
}
.font-size-pluse:hover svg line {
  stroke: #6d6df2;
}
.community-hover:hover svg path {
  color: #292933;
}

@media (min-width: 1200px) and (max-width: 1535px) {
  body .proposal-builder {
    transform: translateX(-50%);
    left: 50%;
    width: 100%;
    padding: 0px 49px;
  }
}
@media (min-width: 1200px) and (max-width: 1487px) {
  body .proposal-builder {
    top: -82px;
  }
  .Proposal-Builder div#printablediv {
    margin-top: 220px;
  }
  .Proposal-Builder div#printablediv .rdw-editor-toolbar.toolbarClassName {
    max-width: 890px;
  }
}

.react-time-input-picker-wrapper {
  height: 37px;
  box-sizing: border-box;
  margin: -10px 0 !important;
  border-bottom: 0px !important;
  display: flex;
  align-items: center;
  width: max-content;
  justify-content: center;
  z-index: 999;
}

.inputWrapper__dropdown {
  z-index: 999;
}
.reson-for-leaving .cl-custom-check {
  display: none;
}
.reson-for-leaving .cl-custom-check + .cl-custom-check-label {
  cursor: pointer;
  user-select: none;
}
.reson-for-leaving .cl-custom-check:checked + .cl-custom-check-label {
  background: #5a5a66;
  color: #ffffff;
  border: 1px solid #5a5a66;
}
.react-time-input-picker-wrapper {
  height: 37px;
  box-sizing: border-box;
  margin: -10px 0 !important;
  border-bottom: 0px !important;
  display: flex;
  align-items: center;
  width: max-content;
  justify-content: center;
  z-index: 999;
}
.inputWrapper__dropdown {
  z-index: 999;
}
.welcome-client-popup {
  zoom: 90%;
}
.glow-animation::after {
  animation-name: grow;
  transition-duration: 3s;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.8);
  }
  100% {
    transform: scale(1);
  }
}
.time-inputs .react-time-input-picker {
  width: 70px;
}
.time-inputs
  .react-time-input-picker
  .inputWrapper:nth-child(1)
  input[type="number"] {
  width: 24px;
  text-align: center;
  min-width: auto;
  font-family: "Noto Sans";
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}
.time-inputs
  .react-time-input-picker
  .inputWrapper:nth-child(2)
  input[type="number"] {
  width: 24px;
  text-align: center;
  min-width: auto;
  font-family: "Noto Sans";
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}
.delete-shadow {
  box-shadow: 0px 8px 24px rgb(0 0 0 / 10%);
}
body .event-time-inputs {
  padding: 13px 0px;
}
body .event-time-inputs .react-time-input-picker-wrapper {
  padding: 0;
}
body
  .event-time-inputs
  .react-time-input-picker-wrapper
  input[type="number"]:focus {
  background: #fff;
}

body .event-time-inputs .react-time-input-picker-wrapper input[type="number"],
body
  .event-time-inputs
  .react-time-input-picker-wrapper
  .inputWrapper:nth-child(1)::after {
  color: #c2c2cc;
}
body
  .event-time-inputs
  .input-text
  .react-time-input-picker-wrapper
  input[type="number"],
body
  .event-time-inputs
  .input-text
  .react-time-input-picker-wrapper
  .inputWrapper:nth-child(1)::after {
  color: #5a5a66;
}
.footer-shadow {
  box-shadow: 0px 94px 80px rgb(0 0 0 / 5%),
    0px 55.3472px 41.728px rgb(0 0 0 / 4%),
    0px 28.4256px 19.584px rgb(0 0 0 / 3%),
    0px 11.4304px 8.576px rgb(0 0 0 / 2%), 0px 2.5568px 3.712px rgb(0 0 0 / 1%);
}
body
  .add-new-event-time-color
  .react-time-input-picker-wrapper
  input[type="number"],
body
  .add-new-event-time-color
  .react-time-input-picker-wrapper
  .inputWrapper:nth-child(1)::after {
  color: #c2c2cc;
}
body
  .add-new-event-time-color
  .input-text
  .react-time-input-picker-wrapper
  input[type="number"],
body
  .add-new-event-time-color
  .input-text
  .react-time-input-picker-wrapper
  .inputWrapper:nth-child(1)::after {
  color: #5a5a66;
}
.onboading-form .swiper {
  overflow: initial;
}
.ship-svg {
  position: absolute;
  top: -4px;
  left: -32px;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
body .indiviualsaccount .ship-svg svg {
  transition: all 0.3s;
  pointer-events: none;
}
body .indiviualsaccount:hover .ship-svg svg {
  left: 0;
}
body .selected-plane-set .ship-svg svg {
  left: 0;
}
.selected-plane-set .duck {
  transform: translate(0px, 0px);
}
body .hover-state:hover {
  transform: translateY(0px);
}
.alert-shadow {
  box-shadow: 0px 10px 40px rgba(41, 41, 51, 0.15);
  border-radius: 16px;
}

/* toster */
body .Toastify__toast-container {
  width: auto;
  min-width: 320px;
  padding: 0;
}
body div#customize-toster {
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
  margin: 0;
  box-shadow: 0px 10px 40px rgb(41 41 51 / 15%);
  position: relative;
}
body div#customize-toster .Toastify__toast-body {
  padding: 0;
}
body div#customize-toster button.Toastify__close-button {
  position: absolute;
  z-index: 9999999;
  top: 15px;
  right: 15px;
  width: 24px;
  height: 24px;
  opacity: 0;
}
.custome-modal {
  position: absolute;
  outline: none;
}
.ReactModal__Overlay::-webkit-scrollbar {
  width: 0px;
}
@media (max-height: 992px) {
  body .ReactModalPortal .custome-modal {
    position: relative;
    max-width: 1047px;
    transform: translate(0, 0) !important;
    inset: 0 !important;
    margin: auto !important;
  }
}
.edite-hover:hover svg path {
  stroke: #5a5a66;
}
.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  color: #5a5a66;
  opacity: 0.69;
}
body .css-njue5v .fc-theme-standard td,
body .css-njue5v .fc-theme-standard th {
  border-color: #dfdfe6;
}
.currency-hover:hover .text-Neutral800 {
  color: #292933;
}

.task-comment-editor .rdw-mention-link {
  pointer-events: none;
  cursor: default;
}

.Toastify .Toastify__toast-container .Toastify__toast--success {
  background: var(--accent-color) !important; /* #6d6df2 */
  /* width: 320px !important; */
  /* height: 56px !important; */
  /* background: #6d6df2 !important; */
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.15) !important;
  border-radius: 32px !important;
  color: #ffffff !important;
}

.Toastify
  .Toastify__toast-container
  .Toastify__toast--success
  .Toastify__toast-icon {
  margin-left: 20px !important;
}
.Toastify
  .Toastify__toast-container
  .Toastify__toast--success
  .Toastify__toast-icon
  svg {
  fill: var(--bg-color) !important; /* #ffffff  */
}
.Toastify
  .Toastify__toast-container
  .Toastify__toast--success
  .Toastify__progress-bar {
  /* display: none !important; */
  background: var(--bg-color) !important; /* #ffffff  */
}

.Toastify
  .Toastify__toast-container
  .Toastify__toast--success
  .Toastify__close-button {
  margin: auto !important;
  margin-right: 10px !important;
  color: #ffffff !important;
  opacity: 2 !important;
  top: 20px !important;
  right: 4px !important;
}

.Toastify
  .Toastify__toast-container
  .Toastify__toast--success
  .Toastify__toast-body {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 13px !important;
  line-height: 100% !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
}
.Toastify__progress-bar--error {
  background: #ffffff !important;
  z-index: 9 !important;
}

.Toastify .Toastify__toast-container .Toastify__toast--error {
  background: #e56780 !important;
  width: 320px !important;
  /* height: 56px !important; */
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.15) !important;
  border-radius: 32px !important;
}

.Toastify
  .Toastify__toast-container
  .Toastify__toast--error
  .Toastify__toast-icon {
  margin-left: 20px !important;
}
.Toastify
  .Toastify__toast-container
  .Toastify__toast--error
  .Toastify__toast-icon
  svg {
  fill: #ffffff !important;
}
/* .Toastify .Toastify__toast-container .Toastify__toast--error .Toastify__progress-bar {
  display: none !important;
} */

.Toastify
  .Toastify__toast-container
  .Toastify__toast--error
  .Toastify__close-button {
  margin: auto !important;
  margin-right: 10px !important;
  color: #ffffff !important;
  opacity: 2 !important;
  top: 20px !important;
  right: 4px !important;
}

.Toastify
  .Toastify__toast-container
  .Toastify__toast--error
  .Toastify__toast-body {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  line-height: 100% !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
}
.Toastify__progress-bar--error {
  background: #ffffff !important;
}

.swiper-slide:last-child {
  margin-right: 0 !important;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input {
  -moz-appearance: textfield;
}

.edit-icon:hover svg path {
  stroke: var(--accent-color); /* #5856d6 */
}

.cropped-avatar {
  --mask: radial-gradient(17px at 135% 50%, #0000 98%, #000) no-repeat;
  -webkit-mask: var(--mask);
  mask: var(--mask);

  margin-right: -4px;
}

.task-divider:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  width: 1px;
  height: 20px;
  border: 0.5px solid #ededf2;
}

.icon-button-gradient {
  background: linear-gradient(
    136.52deg,
    rgba(109, 109, 242, 0.8) 0%,
    #6d6df2 102.68%
  );
  transition: all 250ms ease-in-out;
}

.icon-button-gradient:hover,
.icon-button-gradient:focus {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
}

.fr-view ul,
.fr-view ol {
  list-style: unset;
  margin: unset;
  padding: unset;
}
.fr-view ul {
  list-style-type: disc;
  padding-left: 20px; /* Adjust as needed */
}

.fr-view ul ul {
  list-style-type: circle; /* Style for nested lists, adjust as needed */
  padding-left: 40px; /* Increased indentation for nested lists */
}

.froala-editor {
  max-width: 1000px !important;
  padding: 0 50px !important;
  margin: 0 auto !important;
  width: 100% !important;
}

.fr-toolbar.fr-sticky-on {
  z-index: 9999 !important;
  top: 104px !important;
}

@media (max-width: 1440px) {
  .froala-editor {
    max-width: 930px !important;
    margin-right: 0 !important;
    /* padding: 0 30px !important; */
    padding: 0 !important;
  }
}

@media (max-width: 1400px) {
  .froala-editor {
    max-width: 900px !important;
    /* padding: 0 30px !important; */
    margin-right: 0 !important;
    padding: 0 !important;
  }
}

@media (min-width: 1280px) and (max-width: 1580px) {
  .froala-editor {
    padding: 0 15px !important;
  }
  .fr-wrapper {
    padding: 30px 0 !important;
  }
}

@media (min-width: 960px) and (max-width: 1280px) {
  .froala-editor {
    max-width: 930px !important;
    margin-right: 0 !important;
    padding: 0 0 !important;
  }
  .fr-wrapper {
    padding: 0 0 !important;
  }
}
@media print {
  .fr-box.fr-document .fr-wrapper {
    background: white !important;
  }
}

.react-datepicker {
  z-index: 30 !important;
}

.react-datepicker__month-container {
  color: #253333 !important;
  font-family: "Noto Sans", sans-serif !important;
  border: 0 !important;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.3),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
  -webkit-box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.3),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
  -ms-box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.3),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
  -o-box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.3),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
  -moz-box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.3),
    inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

#category::-webkit-calendar-picker-indicator {
  background-color: inherit;
  appearance: none;
  display: none !important;
}

datalist {
  position: absolute;
  background-color: white;
  border: 1px solid blue;
  border-radius: 0 0 5px 5px;
  border-top: none;
  font-family: sans-serif;
  width: 350px;
  padding: 5px;
  max-height: 10rem;
  overflow-y: auto;
}

option {
  background-color: white;
  padding: 4px;
  color: blue;
  margin-bottom: 1px;
  font-size: 18px;
  cursor: pointer;
}

option:hover,
.active {
  background-color: lightblue;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day {
  color: #292933 !important;
}

.react-datepicker__current-month {
  margin-bottom: 8px;
}

.react-datepicker__day--outside-month {
  color: #253333aa !important;
}

.react-datepicker__day:hover {
  border-radius: 50% !important;
}

.react-datepicker__day--selected {
  background-color: #6c6cef !important;
  border-radius: 50% !important;
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 50% !important;
  background-color: #6d6df280 !important;
}

.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: none !important;
}

.react-datepicker__day-name {
  font-weight: 600 !important;
}

.react-datepicker__navigation span {
  transform: scale(1.1) !important;
}

.custom-date-picker .react-datepicker__navigation {
  top: 10px !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

@media (min-width: 768px) and (max-width: 1023px) {
  .menuitems h3,
  .navbar-title,
  .user-menu-wrapper > div,
  .new-menu,
  .site-title {
    display: none;
  }
  .medium-screen .menuitems h3,
  .medium-screen .navbar-title,
  .medium-screen .new-menu,
  .medium-screen .site-title {
    display: block;
  }
  /* .user-menu-wrapper {
    margin-bottom: 3.75rem;
  }*/
  .medium-screen .user-menu-wrapper > a > div {
    padding-left: 0 !important;
  }
  .user-menu-wrapper > a h4,
  .user-menu-wrapper > a p {
    display: none;
  }
  .medium-screen .user-menu-wrapper > a h4,
  .medium-screen .user-menu-wrapper > a p {
    display: block;
  }
  .menu-title-delimiter {
    font-size: 2px;
  }
  .medium-screen .menu-title-delimiter {
    font-size: 10px;
  }

  .task-add-comment,
  .task-edit-comment {
    width: 100%;
    padding: 16px 32px 24px 48px !important;
  }
  .ReactModal__Overlay {
    overflow: auto !important;
  }
  .ReactModal__Content {
    max-height: 90vh;
    overflow: visible !important;
  }
}

@media (max-width: 767px) {
  .sidebar-main {
    display: none !important;
  }

  .menuitems h3,
  .navbar-title,
  .user-menu-wrapper > div,
  .new-menu,
  .site-title,
  .mobile-menubar,
  .mobile-top-menubar {
    display: block;
  }
  .main-content {
    width: 100%;
    margin-left: 0 !important;
  }
  .selected {
    position: relative;
  }
  .selected::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    height: 4px;
    width: 32px;
    background: #6d6df2;
    border-radius: 0px 0px 2px 2px;
  }
  .clientslist:hover li {
    margin-right: -12px;
  }
  .user-icon-shadow {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25),
      inset 0px -0.5px 0px rgba(0, 0, 0, 0.15),
      inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
  }
  .client:nth-child(6) {
    display: none;
  }
  .admin-navbar {
    width: 100%;
  }

  .fr-toolbar.fr-sticky-on {
    top: 64px !important;
  }
}

@media (min-width: 768px) and (max-width: 920px) {
  .custom-card {
    width: unset;
    max-width: unset;
  }
}

@media (min-width: 920px) and (max-width: 1240px) {
  .custom-card {
    width: calc(50% - 8px) !important;
    height: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 980px) {
  .card-onboarding {
    width: 100% !important;
  }
}

@media (min-width: 1240px) and (max-width: 1400px) {
  .custom-card {
    width: calc(50% - 8px) !important;
  }
}

@media (min-width: 1280px) and (max-width: 1400px) {
  .fr-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  /* .fr-element {
    padding-left: 10px !important;
    padding-right: 10px !important;
  } */
}

.fr-element {
  padding: 0 0 !important;
}

@media (min-width: 1440px) and (max-width: 1560px) {
  .froala-editor {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

.custom-wow-alert {
  display: none;
}

@media (min-width: 2500px) {
  .custom-wow-alert {
    display: block;
  }
}

.temp-width {
  width: calc(100vw - 344px) !important;
}

/* .hover-calendar:hover {
  color: #5281e0 !important;
}

.hover-calendar:hover span {
  color: #173b82 !important;
} */

.frequency-button {
  color: #5281e0 !important;
}

.frequency-button span {
  color: #173b82 !important;
}

.frequency-button .hover-frequency {
  display: none !important;
}

.frequency-button:focus .hover-frequency {
  display: flex !important;
}

/* editor styles */
/* Normal text, assuming it's a paragraph */
/* .fr-element p {
  font-size: 16px;
  color: #333; 
  margin-top: 16px;
  margin-bottom: 16px;
  line-height: 1.5;
} */

/* Heading 1 */
.fr-element h1 {
  font-size: 32px;
  color: #222; /* even darker gray */
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 20px;
  line-height: 1.2;
}

/* Heading 2 */
.fr-element h2 {
  font-size: 28px;
  color: #222;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 18px;
  line-height: 1.25;
}

/* Heading 3 */
.fr-element h3 {
  font-size: 24px;
  color: #444; /* medium-dark gray */
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 16px;
  line-height: 1.3;
}

/* Heading 4 */
.fr-element h4 {
  font-size: 20px;
  color: #444;
  font-weight: bold;
  margin-top: 18px;
  margin-bottom: 14px;
  line-height: 1.35;
}

/* Code */
.fr-element code {
  font-family: "Courier New", Courier, monospace;
  font-size: 16px;
  background-color: #f7f7f7; /* light gray background */
  border: 1px solid #e0e0e0; /* border around the code */
  padding: 2px 4px;
  color: #d43f00; /* code-specific color, you can adjust this */
  display: inline-block;
  border-radius: 4px;
}
/* editor styles */
